<template>
  <div>
    <v-container>
      <v-subheader class="text-subtitle-1 text--primary">
        Exemplo Crud
      </v-subheader>
      <v-card>  
        <v-toolbar flat color="white">
          <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" @click:clear="limparBusca" @keyup.enter="filtrar($event)" max-width="300px" prepend-inner-icon="search" label="Buscar Item"></v-text-field>
          <v-spacer></v-spacer>
          <v-btn text icon :loading="carregando" @click="listar">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-btn color="primary" @click="novo" data-cy="novo">Novo Item</v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table :options.sync="options" :server-items-length="total" :footer-props="footerOptions" :headers="headers" :items="itens" :loading="carregando">
          <template v-slot:item="{item}">
            <tr>
              <td>{{ item.codigo }}</td>
              <td>{{ item.descricao }}</td>
              <td class="text-right">{{ item.valor | formatarMoeda }}</td>
              <td class="text-right">{{ item.estoque | formatarValor }}</td>
              <td class="text-center">
                <v-menu top right transition="slide-x-transition" @click.native.stop="">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" data-cy="menu-acoes">more_vert</v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item :key="item._id" @click="editar(item._id)" data-cy="editar">
                      <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item :key="item._id + 15" @click="excluir(item._id)" data-cy="remover">
                      <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                      <v-list-item-title>Excluir</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
    
</template>

<script>

  import {mapState, mapGetters, mapActions} from 'vuex';
  import constantes from '@/util/constantes';

  export default {
    data () {
      return {
        headers: [
          { text: 'Código', value: 'codigo', sortable: false },
          { text: 'Descrição', value: 'descricao', sortable: false },
          { text: 'Valor', value: 'valor', align: 'right', sortable: false },
          { text: 'Estoque', value: 'estoque', align: 'right', sortable: false },
          { text: 'Ações', value: 'name', align:'center',  sortable: false }
        ],
        constantes: constantes,
        busca: '',
        options: {
          itemsPerPage: 10,
          page: 1
        },
        footerOptions: {
          'items-per-page-options': constantes.ITEMS_POR_PAGINA 
        }
      }
    },

    computed: {
      ...mapState('crud', {
        itens: 'itens',
        carregando: 'carregando',
        total: 'total'
      })
    },

    watch: {
      options: {
        handler () {
          this.listar();
        },
        deep: true,
      },
    },

    methods: {
        
      novo(){
        this.$store.commit('crud/ZERAR');
        this.$router.push({name: 'CrudNovo'});
      },

      async editar(id){
        this.$router.push({name: 'CrudEditar'});
        await this.$store.dispatch('crud/encontrarPeloId', id);
      },

      listar(){
        this.$store.dispatch('crud/listar', this.options); 
      },

      limparBusca(){
        this.listar();
      },

      filtrar(event){
        let newOptions = {
          ...this.options,
          query: this.busca
        }

        this.$store.dispatch('crud/listar', newOptions);
        setTimeout(() => {
          event.target.select();
        }, 300)
      },

      excluir(id){
        this.$root.$confirmacao.open('Excluir', 'Tem certeza que deseja excluir?')
        .then((confirmado) => {
          if(confirmado){
            this.$store.dispatch('crud/excluir', id);
          }
        });
      }
    },
  }
</script>